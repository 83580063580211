.ay_navbar_pc {
   position: -webkit-sticky;
   position: sticky;
   top: 0;
   z-index: 1000;
   width: 100%;
}

.ay_navbar_header {
   position: -webkit-sticky;
   position: sticky;
   top: 0;
   z-index: 1000;
   width: 100%;
   background: white;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.menu a::before {
   content: "";
   width: 100%;
   height: 4px;
   position: absolute;
   left: 0;
   bottom: -10%;
   background: #1dc468;
   transition: 0.8s transform ease;
   transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
   transform: scale3d(0, 1, 1);
   transform-origin: 0 50%;
   border-radius: 50px;
}

.menu a:hover::before {
   transform: scale3d(1, 1, 1);
}

.animatedUnderline::before {
   content: "";
   width: 100%;
   height: 4px;
   position: absolute;
   left: 0;
   bottom: -10%;
   background: #1dc468;
   transition: 0.8s transform ease;
   transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
   transform: scale3d(0, 1, 1);
   transform-origin: 0 50%;
   border-radius: 50px;
}

.animatedUnderline:hover::before {
   transform: scale3d(1, 1, 1);
}

.hamburger {
   z-index: 1003;
}

@media (min-width: 1024px) {
   .ay_navbar_pc {
      transition: all 0.4s;
      /* z-index: auto; */
      position: fixed;
   }

   .ay_navbar_header {
      position: initial;
      top: auto;
      /* z-index: auto; */
      width: auto;
      box-shadow: none;
   }

   .hamburger {
      z-index: auto;
   }
}